<template>
  <div class="block-flyout-regions-mobile">
    <AtomLink
      v-if="data?.content_side?.link?.id"
      :text="data.title"
      :link="data.content_side.link"
      icon="chevron-right"
      class="block-flyout-regions-mobile__title"
    />
    <div v-else class="block-flyout-regions-mobile__title">
      {{ data.title }}
    </div>
    <div class="block-flyout-regions-mobile__links">
      <div
        v-for="link in data.links"
        :key="link._uid"
      >
        <AtomLink
          v-if="isArrayEmpty(link.sub_links) && !link.label_image?.filename"
          class="block-flyout-regions-mobile__link"
          :link="link.link"
          :text="link.label"
        />
        <UtilRouteLink
          v-else-if="isArrayEmpty(link.sub_links)"
          class="block-flyout-regions-mobile__link"
          :link="link.link"
        >
          <p>
            {{ link.label }}
          </p>
          <AtomImage
            v-if="link.label_image?.filename"
            :image="link.label_image"
            class="block-flyout-regions-mobile__link-image"
            provider="storyblok"
          />
        </UtilRouteLink>
        <div
          v-else-if="isArrayNotEmpty(link.sub_links)"
          class="block-flyout-regions-mobile__link"
          @click="setFlyoutData(link)"
          @keypress="setFlyoutData(link)"
        >
          <p>
            {{ link.label }}
          </p>
          <AtomImage
            v-if="link.label_image?.filename"
            :image="link.label_image"
            class="block-flyout-regions-mobile__link-image"
            provider="storyblok"
          />
        </div>
      </div>
      <UtilRouteLink
        v-if="data?.link?.id || data?.link?.url"
        :link="data.link"
      >
        <AtomButton
          :text="data.link_label"
          :color-schema="buttonColor"
          variant="inverted"
          html-tag="div"
        />
      </UtilRouteLink>
    </div>
    <div class="block-flyout-regions-mobile__gradient" />
    <div class="block-flyout-regions-mobile__navigation">
      <div
        class="block-flyout-regions-mobile__navigation-back"
        @click="setPreviousFlyoutData()"
        @keypress="setPreviousFlyoutData()"
      >
        <IonIcon icon-name="chevron-left" />
        <p>{{ getStoryblokTranslation('general', 'back') }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
    data: {
        type: Object,
        default: () => {},
    },

    backgroundColorMain: {
        type: String,
        default: '',
    },
});

const flyoutStore = useFlyoutStore();
const buttonColor = computed(() => flyoutStore.getFlyoutColor);

const setFlyoutData = (data) => {
    // prepare data for flyoutstore
    const flyoutData = {
        title: data.label,
        links: data.sub_links,
        content_side: data?.content_side[0],
    };

    // set flyout data main
    flyoutStore.setFlyoutDataMain(flyoutData);
    flyoutStore.setFlyoutRegionCountry(data.label);

    // set flyout data side
    if (data?.content_side?.length > 0) {
        flyoutStore.setFlyoutDataSide(data?.content_side[0]);
    }
};

const setPreviousFlyoutData = () => {
    flyoutStore.setFlyoutDataMain();
};
</script>

<style lang="scss" scoped>
.block-flyout-regions-mobile {
    display: flex;
    flex-direction: column;
    color: var(--c-white);
    row-gap: 40px;
}

.block-flyout-regions-mobile__gradient {
    position: absolute;
    z-index: 1;
    bottom: 114px;
    width: 100%;
    height: 211px;
    background: linear-gradient(0deg, v-bind(backgroundColorMain) 54.69%, rgba(110, 54, 52, 0) 100%);
    content: '';
    pointer-events: none;

    @include mobile {
        height: 114px;
    }
}
.block-flyout-regions-mobile__title {
    z-index: 2;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--h4);
    line-height: var(--l-height--h4);

    @include mobile {
        font-size: var(--f-size--h3);
        line-height: var(--l-height--h3);

        span {
            font-family: var(--f-family--regular)
        }
    }
}

.block-flyout-regions-mobile__links {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-left: 30px;
    row-gap: 20px;
}

.block-flyout-regions-mobile__link {
    position: relative;
    display: flex;
    column-gap: 20px;
    cursor: pointer;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    &:after {
        position: absolute;
        bottom: -6px;
        display: block;
        width: 32px;
        height: 2px;
        border: 50%;
        background-color: var(--c-white);
        content: '';
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    &:hover {
        &:after {
            opacity: 1;
        }
    }
}

.block-flyout-regions-mobile__link-image {
    width: 32px;
    height: 22px;
}

.block-flyout-regions-mobile__navigation {
    position: absolute;
    bottom: 142px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.block-flyout-regions-mobile__navigation-top {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    white-space: nowrap;
}

.block-flyout-regions-mobile__navigation-pipe {
    width: 1px;
    height: 29px;
    background: var(--c-white);
}

.block-flyout-regions-mobile__navigation-back {
    z-index: 1;
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);

    .ion-icon {
        transition: all 0.3s ease;
    }

    &:hover {
        .ion-icon {
            transform: translate(-5px, 0)
        }
    }
}
</style>
