<template>
  <div
    class="block-nav-links__content-main-links-inner"
    @mouseleave="resetData"
    @focusout="resetData"
  >
    <div
      v-if="filteredLinks.length > 0"
      class="block-nav-links__content-main-links"
    >
      <div
        v-for="link, index in filteredLinks"
        :key="link._uid"
        class="block-nav-links__content-main-links-link"
        :class="{ 'is-active': currentDataIndex === index }"
      >
        <AtomLink
          v-if="link.sub_links?.length === 0"
          :text="link.label"
          :link="link.link"
          icon="chevron-right"
          :is-auth="link.isAuth"
        />

        <div
          v-else
          @mouseover="currentDataIndex !== index ? setSubLinkData(link, index) : null"
          @focus="currentDataIndex !== index ? setSubLinkData(link, index) : null"
        >
          {{ link.label }}
          <IonIcon
            icon-name="chevron-right"
            class="atom-link__icon"
          />
        </div>
      </div>
    </div>
    <div
      v-if="subLinks.length !== 0"
      class="block-nav-links__content-main-sub-links"
      @mouseleave="resetData"
      @focusout="resetData"
    >
      <div
        v-for="link in subLinks"
        :key="link._uid"
      >
        <AtomLink
          v-if="link.component === 'NavItem'"
          :text="link.label"
          :link="link.link"
          :is-auth="link.isAuth"
          icon="none"
          class="block-nav-links__content-main-sub-links-link"
        />

        <a
          v-else-if="link.component === 'NavItemDownload'"
          :href="link.file.filename"
          target="_blank"
          rel="noopener noreferrer"
          class="block-nav-links__content-main-sub-links-download"
        >
          {{ link.label }}
          <IonIcon icon-name="arrow-download" />
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

const subLinks = ref([]);
const currentDataIndex = ref(null);

const setSubLinkData = (link, index) => {
    currentDataIndex.value = index;
    subLinks.value = link.sub_links;
};

const resetData = () => {
    currentDataIndex.value = null;
    subLinks.value = [];
};

const authStore = useAuthStore();
const isAuthenticated = computed(() => authStore.getIsAuthenticated);
const filteredLinks = computed(
    // filter lins if isAuth is true and no user is logged in
    () => props.data.content[0].links.filter((link) => !link.isAuth || isAuthenticated.value),
);
</script>

<style lang="scss">
.block-nav-links__content-main-links-inner {
    display: flex;
    column-gap: 60px;

    .ion-icon {
        transition: all 0.3s ease;
    }
}

.block-nav-links__content-main-links {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.block-nav-links__content-main-links-link {
    position: relative;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--h3);
    line-height: var(--l-height--h3);

    div {
        display: flex;
        align-items: center;
        column-gap: 10px;
        cursor: pointer;
    }

    &:after {
        position: absolute;
        bottom: -6px;
        display: block;
        width: 32px;
        height: 2px;
        border: 50%;
        background-color: var(--c-white);
        content: '';
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .ion-icon {
        transition: transform 0.3s ease;
    }

    &:hover {
        &:after {
            opacity: 1;
        }

        .ion-icon {
            transform: translate(10px, 0);
        }
    }

    &.is-active {
        &:after {
            opacity: 1;
        }

        .ion-icon {
            transform: translate(10px, 0);
        }
    }
}

.block-nav-links__content-main-sub-links {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.block-nav-links__content-main-sub-links-link {
    position: relative;

    &:after {
        position: absolute;
        bottom: -6px;
        display: block;
        width: 32px;
        height: 2px;
        border: 50%;
        background-color: var(--c-white);
        content: '';
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    &:hover {
        &:after {
            opacity: 1;
        }
    }
}

.block-nav-links__content-main-sub-links-download {
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    &:hover {
        .ion-icon {
            transform: translateX(10px);

            @include tablet {
                transform: translateX(0);
            }
        }
    }
}
</style>
