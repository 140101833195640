<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<template>
  <div
    class="block-header__wrapper"
    :class="{ 'hide-header': scrollDirection === 'down' }"
    @mouseleave="resetData"
  >
    <div class="block-header">
      <div
        class="block-header__inner"
      >
        <UtilRouteLink :link="localePath('/')">
          <IonIcon
            icon-name="logo"
            class="block-header__logo"
          />
        </UtilRouteLink>

        <div class="block-header__links">
          <UtilRouteLink :link="localePath('/')">
            <IonIcon
              icon-name="home"
              class="block-header__logo-home"
            />
          </UtilRouteLink>
          <nav class="block-header__nav">
            <ul class="block-header__nav-list">
              <li
                v-for="(link, index) in links"
                :key="link._uid"
                :class="{ 'is-active': activeTab === index }"
                class="block-header__nav-list-item"

                @mouseover="currentDataIndex !== index ? setFlyoutData(link, index) : null"
                @focus="currentDataIndex !== index ? setFlyoutData(link, index) : null"
              >
                <UtilRouteLink
                  v-if="link.link"
                  :link="link.link"
                >
                  <div
                    class="block-header__nav-list-link"
                  >
                    {{ link.text }}
                  </div>
                </UtilRouteLink>
                <div
                  v-else
                  class="block-header__nav-list-link"
                >
                  {{ link.text }}
                </div>
              </li>
            </ul>
          </nav>
        </div>
        <div class="block-header__navigation">
          <UtilRouteLink
            v-if="!loginOpenOverlay && linkLogin?.id || linkLogin?.url "
            :link="linkLogin"
            class="block-header__navigation-login"
          >
            <p>{{ getStoryblokTranslation('general', 'login') }}</p>
            <IonIcon icon-name="login" />
          </UtilRouteLink>

          <div
            v-else-if="loginOpenOverlay && !isAuthenticated"
            class="block-header__navigation-login"
            @click="modalboxStore.openModalbox"
            @keypress="modalboxStore.openModalbox"
          >
            <p>{{ getStoryblokTranslation('general', 'login') }}</p>
            <IonIcon icon-name="login" />
          </div>

          <div
            v-else-if="loginOpenOverlay && isAuthenticated"
            class="block-header__navigation-login"
            @click="logoutUser"
            @keypress="logoutUser"
          >
            <p>{{ getStoryblokTranslation('general', 'logout') }}</p>
            <IonIcon icon-name="logout" />
          </div>

          <Transition name="fade-in">
            <BlockMobalboxLogin v-if="modalboxStore.isOpen" />
          </Transition>

          <BlockNavigationLanguage
            class="block-header__navigation-lang"
            :class="{ 'is-active': flyoutOpen }"
          />
          <AtomMenuBurger @toggle-menu-mobile="toggleMenuMobile()" />
        </div>
      </div>
    </div>
    <div class="block-header__flyout__wrapper">
      <BlockFlyout
        v-if="flyoutData && !isTablet && flyoutOpen"
        :data="flyoutData"
        class="block-header__flyout"
        @wheel.prevent
        @touchmove.prevent
        @scroll.prevent
        @close-flyout="showFlyout = false, activeTab = null"
      />
      <BlockFlyoutMobile
        v-if="isTablet && flyoutOpen"
        :links="links"
        :data="flyoutData"
        :link-login="linkLogin"
      />
    </div>
  </div>
</template>

<script setup>
defineProps({
    links: {
        type: Array,
        default: () => [],
    },
});

const localePath = useLocalePath();

const flyoutStore = useFlyoutStore();
const flyoutOpen = computed(() => flyoutStore.getFlyoutState);
const flyoutData = ref('');
const showFlyout = ref(false);

const activeTab = ref(null);
const savedTabIndex = ref(null);
const currentDataIndex = ref(null);
const route = useRoute();
const activeRoute = computed(() => route.path);

const clientDeviceStore = useClientDeviceStore();
const isTablet = computed(() => clientDeviceStore.isTablet);
const isGreaterThanTablet = computed(() => clientDeviceStore.isGreaterThanTablet);

const flyoutColor = computed(() => flyoutStore.getFlyoutColor);
const backgroundColorTab = ref('var(--c-petrol-2)');
const backgroundColorTabSaved = ref('var(--c-petrol-2)');

const modalboxStore = useModalboxStore();
const authStore = useAuthStore();
const isAuthenticated = computed(() => authStore.getIsAuthenticated);

watch(flyoutColor, () => {
    if (flyoutColor.value) {
        backgroundColorTab.value = `var(--c-${flyoutColor.value}-2)`;
    } else if (!flyoutColor.value) {
        backgroundColorTab.value = 'var(--c-petrol-2)';
    }
});

watch(flyoutOpen, () => {
    if (flyoutOpen.value && isTablet.value) {
        document.body.classList.add('no-scroll');
    } else {
        document.body.classList.remove('no-scroll');
    }
});

const rerenderBlock = ref(0);
const forceRerender = () => {
    rerenderBlock.value += 1;
};

const setFlyoutData = (data, index) => {
    flyoutStore.resetFlyoutData();
    flyoutStore.setFlyoutState(true);
    flyoutStore.setFlyoutNavName(data?.text);

    flyoutData.value = data;
    activeTab.value = index;
    showFlyout.value = true;
    currentDataIndex.value = index;
    backgroundColorTab.value = 'var(--c-petrol-2)';

    forceRerender();
};

const resetData = () => {
    if (isGreaterThanTablet.value) {
        backgroundColorTab.value = backgroundColorTabSaved.value;
        activeTab.value = savedTabIndex.value;
        showFlyout.value = false;
        flyoutStore.setFlyoutState(false);
        currentDataIndex.value = null;
    }
};

const linkLogin = getStoryblokSetting('navigation', 'link_login');
const loginOpenOverlay = getStoryblokSetting('navigation', 'loginOpenOverlay');

const slugsStiftung = getStoryblokSetting(
    'navigation',
    'slug_stiftung',
);

const slugsSchweiz = getStoryblokSetting(
    'navigation',
    'slug_schweiz',
);
const slugsInternational = getStoryblokSetting(
    'navigation',
    'slug_international',
);
const slugsNews = getStoryblokSetting(
    'navigation',
    'slug_news',
);

const slugsJobs = getStoryblokSetting(
    'navigation',
    'slug_jobs',
);

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const color2 = computed(() => `var(--c-${colorTopLevel.value}-2)`);
const color4 = computed(() => `var(--c-${colorTopLevel.value}-4)`);

const checkRoute = () => {
    if (activeRoute.value.includes(slugsJobs.value)) {
        savedTabIndex.value = 4;
        activeTab.value = savedTabIndex.value;
        backgroundColorTab.value = 'var(--c-petrol-2)';
        backgroundColorTabSaved.value = 'var(--c-petrol-2)';
        return;
    }

    if (activeRoute.value.includes(slugsStiftung.value)) {
        // Stiftung is the first tab
        savedTabIndex.value = 0;
        activeTab.value = savedTabIndex.value;
        backgroundColorTab.value = 'var(--c-petrol-2)';
        backgroundColorTabSaved.value = 'var(--c-petrol-2)';
        return;
    }

    if (activeRoute.value.includes(slugsSchweiz.value)) {
        // Schweiz is the second tab
        savedTabIndex.value = 1;
        activeTab.value = savedTabIndex.value;
        backgroundColorTab.value = color2.value;
        backgroundColorTabSaved.value = color2.value;
        return;
    }

    if (activeRoute.value.includes(slugsInternational.value)) {
        // International is the third tab
        savedTabIndex.value = 2;
        activeTab.value = savedTabIndex.value;
        backgroundColorTab.value = 'var(--c-coral-2)';
        backgroundColorTabSaved.value = 'var(--c-coral-2)';
        return;
    }

    if (activeRoute.value.includes(slugsNews.value)) {
        // News is the fourth tab
        savedTabIndex.value = 3;
        activeTab.value = savedTabIndex.value;
        backgroundColorTab.value = 'var(--c-petrol-2)';
        backgroundColorTabSaved.value = 'var(--c-petrol-2)';
        return;
    }

    activeTab.value = null;
    savedTabIndex.value = null;
};

checkRoute();

// watch route path for changes
watch(activeRoute, () => {
    resetData();
    checkRoute();
});

const scrollDirection = ref('up');

onMounted(() => {
    let lastScrollTop = 0;

    window.addEventListener('scroll', () => {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;

        if (scrollTop > lastScrollTop) {
            scrollDirection.value = 'down';
        } else if (scrollTop < lastScrollTop - 10) {
            scrollDirection.value = 'up';
        }
        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    });
});

/*
  Logout user
*/
const logoutUser = () => {
    authStore.logUserOut();
};
</script>

<style lang="scss" scoped>
.block-header__wrapper {
    @include z-index('header');

    position: sticky;
    top: 0;
    transition: top 0.3s ease;

    &.hide-header {
        top: -150px;

        @include mobile {
            top: -70px;
        }
    }
}

.block-header {
    @include fluid('padding-left', 30px, 80px);
    @include fluid('padding-right', 30px, 80px);

    background-color: var(--c-white);

    @include tablet {
        display: flex;
        width: 100%;
        height: 150px;
        flex-direction: column;
        justify-content: center;
    }

    @include mobile {
        height: 70px;
    }
}

.block-header__flyout__wrapper {
    @include z-index('header');

    position: relative;

    @include tablet {
        position: unset;
    }
}

.block-header__flyout {
    position: absolute;
    top: -1px;
    width: 100%;
    height: 631px;
}

.block-header__button {
    display: none;

    @include tablet {
        display: block;
    }
}

.block-header__inner {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.block-header__links {
    display: flex;
    align-items: center;
    align-self: flex-end;
    padding-top: 26px;
    column-gap: 50px;

    @include tablet {
        display: none;
    }
}

.block-header__logo {
    width: 100px;
    height: 60px;

    .klik-letter {
        fill: var(--c-primary);
    }

    @include tablet {
        width: 108px;
        height: 42px;
    }

    @include mobile {
        width: 46px;
        height: 18px;
    }
}

.block-header__nav-list {
    display: flex;
}

.block-header__nav-list-item {
    position: relative;
    color: v-bind(color1);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    &.is-active{
        background: v-bind(backgroundColorTab);
        color: var(--c-white);
    }
}

.block-header__nav-list-link {
    position: relative;
    padding: 40px 40px;

    &:after {
        position: absolute;
        bottom: 34px;
        display: block;
        width: 32px;
        height: 2px;
        border: 50%;
        background-color: v-bind(color1);
        content: '';
        opacity: 0;
    }

    .is-active & {
        &:after {
            position: absolute;
            bottom: 34px;
            display: block;
            width: 32px;
            height: 2px;
            border: 50%;
            background-color: var(--c-white);
            content: '';
            opacity: 1;
        }
    }
}

.block-header__navigation {
    display: flex;
    align-items: center;
    column-gap: 30px;
}

.block-header__navigation-login {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 16px 30px;
    border: 2px solid v-bind(color4);
    border-radius: 4px;
    margin-top: 17px;
    color: v-bind(color4);
    column-gap: 10px;
    cursor: pointer;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--button);
    line-height: var(--l-height--button);
    transition: all 0.2s $EASE_IN_OUT--SINE;

    p {
        padding-top: 3px;
    }

    &:hover {
        background: v-bind(color4);
        color: var(--c-white);
    }

    @include tablet {
        display: none;
    }
}

.block-header__navigation-lang {
    @include tablet {
        display: none;

        &.is-active {
            display: block;
        }
    }
}
</style>
