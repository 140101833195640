<template>
  <button
    class="atom-menu-burger"
    type="button"
    :class="[{ 'is-open': flyoutState }]"
    aria-label="Navigation"
    @click="toggleMenuState()"
    @keypress="toggleMenuState()"
  />
</template>

<script setup>
const flyoutStore = useFlyoutStore();
const flyoutState = computed(() => flyoutStore.getFlyoutState);

const toggleMenuState = () => {
    flyoutStore.resetFlyoutData();

    if (flyoutState.value) {
        flyoutStore.setFlyoutState(false);
        return;
    }

    flyoutStore.setFlyoutState(true);
};

const colorTopLevel = inject('colorSchema');
const colorBackground = computed(() => `var(--c-${colorTopLevel.value}-1)`);
</script>

<style lang="scss" scoped>
$burgerHeight: 20px;
$burgerHeightMobile: 8px;
$burgerThickness: 5px;
$burgerThicknessMobile: 2px;

.atom-menu-burger {
    @include z-index('menuBurger');
    position: relative;
    display: none;
    width: 50px;
    height: $burgerHeight;
    transform-origin: center;
    transition: transform 0.6s $EASE_IN_OUT--BACK;

    @include mobile {
        width: 20px;
        height: $burgerHeightMobile;
    }

    &:after,
    &:before {
        position: absolute;
        width: 100%;
        height: $burgerThickness;
        border-radius: 21px;
        background: v-bind(colorBackground);
        content: '';
        transform-origin: center;
        transition: top 0.3s ease-in-out 0.5s, transform 0.6s ease-in-out,
            background 0.6s ease-in-out;

        @include mobile {
            height: $burgerThicknessMobile;
        }
    }

    &:before {
        top: 0;
    }

    &:after {
        top: calc($burgerHeight - $burgerThickness);

        @include mobile {
            top: calc($burgerHeightMobile - $burgerThicknessMobile);
        }
    }

    &.is-open {
        &:after,
        &:before {
            background: v-bind(colorBackground);
            transition: top 0.3s ease-in-out,
                transform 0.6s $EASE_IN_OUT--BACK 0.15s, background 0.6s ease-in-out;
        }

        &:before {
            top: calc($burgerHeight / 2 - $burgerThickness);
        }

        &:after {
            top: calc($burgerHeight / 2 - $burgerThickness);
            transform: rotate(90deg);
        }

        transform: rotate(225deg);
    }

    @include tablet {
        display: block;
    }
}
</style>
