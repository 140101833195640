<template>
  <nav
    ref="wrapperRef"
    class="block-navigation-language"
    :class="{
      'is-open': isOpen,
      'is-only-single-lang': activeLocales.length === 1,
    }"
  >
    <div
      class="block-navigation-language__active-lang"
      @click="toggleOpen()"
      @keypress="toggleOpen()"
    >
      <p>{{ activeLocale }}</p>
      <div class="block-navigation-language__icon__wrapper">
        <IonIcon
          v-if="isArrayNotEmpty(activeLocales) && activeLocales.length > 1"
          icon-name="chevron-down"
          :class="{
            'is-hidden': isArrayNotEmpty(activeLocales) && activeLocales.length === 1,
          }"
          class="block-navigation-language__icon"
        />
      </div>
    </div>

    <ul
      v-if="isArrayNotEmpty(activeLocales) && activeLocales.length > 1"
      class="block-navigation-language__list"
    >
      <li
        v-for="lang in activeLocales"
        :key="`locale-${lang}-${getUid()}`"
        class="block-navigation-language__list-item"
        :class="{
          'is-hidden': (
            locale === lang || lang === activeLocale
          ),
        }"
      >
        <NuxtLink
          :to="processLocalePath(lang)"
          class="block-navigation-language__list-item-link"
          @click="flyoutStore.setFlyoutState(false)"
          @keypress="flyoutStore.setFlyoutState(false)"
        >
          {{ lang }}
        </NuxtLink>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core';

/*
  Internationalization
*/
const flyoutStore = useFlyoutStore();
const switchLocalePath = useSwitchLocalePath();
const { locale } = useI18n();
const route = useRoute();
const currentPath = computed(() => route.path.replace(`/${locale.value}`, ''));
const storyblokContentStore = useStoryblokContentStore();
const pageLanguages = computed(() => storyblokContentStore.getPageLanguages);

/*
  Handle home link
*/
const processLocalePath = (langCode) => {
    let path = switchLocalePath(langCode);

    // Remove query params
    path = path.replace(/\?.*$/, '');

    if (path.endsWith('/home')) {
        path = path.replace(/home$/, '');
    }

    return path;
};

/*
  Dropdown functionality
*/
const isOpen = ref(false);
const toggleOpen = () => {
    isOpen.value = !isOpen.value;
};

watch(locale, () => {
    isOpen.value = false;
});

const wrapperRef = ref(null);

onClickOutside(wrapperRef, () => {
    isOpen.value = false;
});

/*
  Handle branch languages logic
*/
const branches = getStoryblokSetting('language', 'branches');

const activeLocales = computed(() => {
    let branchLanguages = [
        'de',
        'it',
        'fr',
    ];

    if (isArrayNotEmpty(pageLanguages.value)) {
        branchLanguages = pageLanguages.value;
    } else {
        branches.value.forEach((branch) => {
            if (currentPath.value.includes(branch.path)) {
                branchLanguages = branch.languages;
            }
        });
    }

    return branchLanguages;
});

/*
  Handle active locale
*/
const activeLocale = computed(() => {
    if (activeLocales.value.includes(locale.value)) {
        return locale.value;
    }

    return activeLocales.value[0];
});

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
</script>

<style lang="scss" scoped>
.block-navigation-language {
    position: relative;
    padding-top: 26px;
    color: v-bind(color1);
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    text-transform: uppercase;

    @include tablet {
        padding: unset;
    }
}

.block-navigation-language__active-lang {
    display: flex;
    align-items: center;
    padding: 0 10px 5px 10px;
    column-gap: 5px;
    cursor: pointer;

    .is-only-single-lang & {
        cursor: default;
    }

    @include tablet {
        padding: unset;
    }
}

.block-navigation-language__icon {
    margin-top: -3px;
    transition: transform 0.6s $EASE_IN_OUT--BACK;

    &.is-hidden {
        opacity: 0;
    }

    .is-open & {
        transform: rotate(180deg);
    }
}

.block-navigation-language__icon__wrapper {
    width: 20px;
    height: 23px;
}

.block-navigation-language__list {
    @include z-index('langNav');

    position: absolute;
    display: none;
    width: 100%;
    flex-direction: column;
    background: var(--c-white);

    .is-open & {
        display: flex;
    }
}

.block-navigation-language__list-item {
    position: relative;
    display: flex;
    padding: 5px 10px;
    color: var(--c-secondary);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    text-transform: uppercase;

    &:last-child {
        &:after {
            display: none;
        }
    }

    &:hover {
        text-decoration: underline;
        text-underline-offset: 5px;
    }

    &.is-hidden {
        display: none;
    }

    @include tablet {
        padding: 5px 0;
    }
}

.block-navigation-language__list-item-link {
    color: v-bind(color1);
    cursor: pointer;
}
</style>
