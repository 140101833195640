<template>
  <div class="block-flyout">
    <BlockFlyoutSide
      v-if="data.content_side.length > 0"
      :data="flyoutDataSide ? flyoutDataSide : data.content_side[0]"
      :background-color="backgroundColorSide"
    />

    <div class="block-flyout__content-main">
      <div
        v-if="!flyoutDataMain"
        class="block-flyout__content-main-main"
      >
        <component
          :is="availableComponents[item?.content[0]?.component]"
          v-for="item in data.content_main"
          :key="item._uid"
          :data="item"
        />
      </div>
      <BlockFlyoutDepartments
        v-if="flyoutDataMain && !isInternational"
        :data="flyoutDataMain"
      />

      <BlockFlyoutRegions v-if="flyoutDataMain && isInternational" :data="flyoutDataMain" />

      <BlockFlyoutContact
        v-if="data.content_main.length > 0"
        :data="flyoutDataContact ? flyoutDataContact : data.content_main[0]"
      />
    </div>
  </div>
</template>

<script setup>
defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

const availableComponents = {
    BlockNavLinks: resolveComponent('BlockNavLinks'),
    BlockNavDepartments: resolveComponent('BlockNavDepartments'),
    BlockNavInternational: resolveComponent('BlockNavInternational'),
};

const flyoutStore = useFlyoutStore();
const isInternational = computed(() => flyoutStore.getFlyoutIsInternational);
const flyoutDataMain = computed(() => flyoutStore.getFlyoutDataMain);
const flyoutDataSide = computed(() => flyoutStore.getFlyoutDataSide);
const flyoutDataContact = computed(() => flyoutStore.getFlyoutDataContact);
const flyoutColor = computed(() => flyoutStore.getFlyoutColor);
const backgroundColorMain = ref('var(--c-petrol-2)');
const backgroundColorSide = ref('var(--c-petrol-4)');

watchEffect(() => {
    if (flyoutColor.value) {
        backgroundColorMain.value = `var(--c-${flyoutColor.value}-2)`;
        backgroundColorSide.value = `var(--c-${flyoutColor.value}-4)`;
    } else if (!flyoutColor.value) {
        backgroundColorMain.value = 'var(--c-petrol-2)';
        backgroundColorSide.value = 'var(--c-petrol-4)';
    }
});

</script>

<style lang="scss" scoped>
.block-flyout {
    display: flex;

    .block-flyout-side__content-side {
        background: v-bind(backgroundColorSide);
    }
}

.block-flyout__content-main {
    display: flex;
    width: 100%;
    padding: 60px 80px 45px 40px;
    background: v-bind(backgroundColorMain);
    color: var(--c-white);
}

.block-flyout__content-main-main {
    @include z-index('navFlyout');

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    row-gap: 30px;
}

.block-flyout__content-main-sub-links {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}
</style>
