<template>
  <div class="block-modalbox-login">
    <button
      class="block-modalbox-login__close"
      type="button"
      @click="modalboxStore.closeModalbox"
      @keypress="modalboxStore.closeModalbox"
    >
      <IonIcon
        class="block-modalbox-login__close-icon"
        icon-name="close"
      />
    </button>
    <UtilWrapperLayout>
      <UtilGrid>
        <div ref="wrapperRef" class="block-modalbox-login__container">
          <BlockFormLogin />
        </div>
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>
defineProps({
    data: {
        type: Object,
        required: true,
        default: () => ({}),
    },
});

const modalboxStore = useModalboxStore();
</script>

<style lang="scss" scoped>
.block-modalbox-login {
    @include z-index('modalbox');

    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: var(--c-white);

    @include tablet {
        align-items: flex-start;
        padding-top: 120px;
    }
}

.block-modalbox-login__close {
    @include z-index('modalbox');

    position: absolute;
    top: 50px;
    right: 50px;

    @include tablet {
        top: 20px;
        right: 20px;
    }
}

.block-modalbox-login__container {
    @include default-content-columns;
}
</style>
