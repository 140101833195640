<template>
  <div
    class="block-flyout-mobile"
  >
    <div
      class="block-flyout-mobile__wrapper-content"
      :class="{ 'data-selected': flyoutDataMain }"
    >
      <nav class="block-flyout-mobile__nav">
        <UtilRouteLink :link="localePath('/')">
          <IonIcon
            icon-name="home"
            class="block-flyout-mobile__logo-home"
          />
        </UtilRouteLink>
        <ul class="block-flyout-mobile__nav-list">
          <li
            v-for="link, index in links"
            :key="link._uid"
            class="block-flyout-mobile__nav-list-item"
            @click="setFlyoutData(link, index)"
            @keypress="setFlyoutData(link, index)"
          >
            <div class="block-flyout-mobile__nav-list-link">
              {{ link.text }}
            </div>
          </li>
        </ul>
        <div class="block-flyout-mobile__gradient" />
      </nav>
      <div v-if="flyoutDataMain" class="block-flyout-mobile__content">
        <component
          :is="availableComponents[item.content[0].component]"
          v-for="item in flyoutDataMain.content_main"
          :key="item._uid"
          :data="item"
          :data-side="flyoutDataSide"
        />

        <BlockFlyoutDepartmentsMobile
          v-if="!flyoutDataMain?.content_main && !isInternational"
          :data="flyoutDataMain"
          :background-color-main="backgroundColorMain"
        />

        <BlockFlyoutRegionsMobile
          v-if="!flyoutDataMain?.content_main && isInternational"
          :data="flyoutDataMain"
          :background-color-main="backgroundColorMain"
        />

        <BlockFlyoutContact
          v-if="flyoutDataContact"
          :data="flyoutDataContact"
        />
      </div>
    </div>
    <div class="block-flyout-mobile__bottom">
      <UtilRouteLink
        v-if="!loginOpenOverlay && linkLogin?.id || linkLogin?.url"
        :link="linkLogin"
        class="block-header__navigation-login-mobile"
      >
        <p>{{ getStoryblokTranslation('general', 'login') }}</p>
        <IonIcon icon-name="login" />
      </UtilRouteLink>

      <div
        v-else-if="loginOpenOverlay && !isAuthenticated"
        class="block-header__navigation-login-mobile"
        @click="modalboxStore.openModalbox"
        @keypress="modalboxStore.openModalbox"
      >
        <p>{{ getStoryblokTranslation('general', 'login') }}</p>
        <IonIcon icon-name="login" />
      </div>

      <div
        v-else-if="loginOpenOverlay && isAuthenticated"
        class="block-header__navigation-login-mobile"
        @click="logoutUser"
        @keypress="logoutUser"
      >
        <p>{{ getStoryblokTranslation('general', 'logout') }}</p>
        <IonIcon icon-name="logout" />
      </div>

      <Transition name="fade-in">
        <BlockMobalboxLogin v-if="modalboxStore.isOpen" />
      </Transition>
    </div>
  </div>
</template>

<script setup>
defineProps({
    data: {
        type: Object,
        default: () => {},
    },

    links: {
        type: Object,
        default: () => {},
    },

    linkLogin: {
        type: String,
        default: '',
    },
});

const flyoutStore = useFlyoutStore();
const flyoutDataMain = computed(() => flyoutStore.getFlyoutDataMain);
const flyoutDataSide = computed(() => flyoutStore.getFlyoutDataSide);
const flyoutDataContact = computed(() => flyoutStore.getFlyoutDataContact);
const isInternational = computed(() => flyoutStore.getFlyoutIsInternational);
const flyoutColor = computed(() => flyoutStore.getFlyoutColor);
const backgroundColorMain = ref('var(--c-petrol-2)');
const backgroundColorSide = ref('var(--c-petrol-4)');
const localePath = useLocalePath();

watch(flyoutColor, () => {
    if (flyoutColor.value) {
        backgroundColorMain.value = `var(--c-${flyoutColor.value}-2)`;
        backgroundColorSide.value = `var(--c-${flyoutColor.value}-4)`;
    } else if (!flyoutColor.value) {
        backgroundColorMain.value = 'var(--c-petrol-2)';
        backgroundColorSide.value = 'var(--c-petrol-4)';
    }
});

const availableComponents = {
    BlockNavLinks: resolveComponent('BlockNavLinksMobile'),
    BlockNavDepartments: resolveComponent('BlockNavDepartmentsMobile'),
    BlockNavInternational: resolveComponent('BlockNavInternationalMobile'),
};

const setFlyoutData = (link) => {
    flyoutStore.setFlyoutDataContact(
        link.content_main[0].contact_image,
        link.content_main[0].contact_title,
        link.content_main[0].contact_text,
        link.content_main[0].contact_link,
        link.content_main[0].contact_link_label,
    );

    flyoutStore.setFlyoutDataMain(link);
    flyoutStore.setFlyoutDataSide(link.content_side[0]);
};

const modalboxStore = useModalboxStore();
const authStore = useAuthStore();
const isAuthenticated = computed(() => authStore.getIsAuthenticated);
const loginOpenOverlay = getStoryblokSetting('navigation', 'loginOpenOverlay');

/*
  Logout user
*/
const logoutUser = () => {
    authStore.logUserOut();
};

const colorTopLevel = inject('colorSchema');
const color4 = computed(() => `var(--c-${colorTopLevel.value}-4)`);
</script>

<style lang="scss" scoped>
.block-flyout-mobile {
    position: absolute;
    z-index: 100;
    top: 150px;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: calc(100dvh - 150px);
    flex-direction: column;
    justify-content: space-between;
    background: var(--c-white);

    @include mobile {
        top: 70px;
        height: calc(100dvh - 70px);
    }
}

.block-flyout-mobile__wrapper-content {
    display: flex;
    height: 100%;
    transition: transform 0.2s ease;

    &.data-selected {
        transform: translateX(-100%);
    }
}

.block-flyout-mobile__nav {
    overflow: scroll;
    min-width: 100vw;
    height: calc(100% - 114px);
    padding-left: 155px;
    margin-top: 60px;
    background: var(--c-white);

    @include mobile {
        padding-left: 25px;
        margin-top: 32px;
    }
}

.block-flyout-mobile__content {
    display: flex;
    overflow: scroll;
    min-width: 100vw;
    height: calc(100% - 211px);
    flex-direction: column;
    padding: 52px 32px;
    background: v-bind(backgroundColorMain);
    row-gap: 20px;

    @include mobile {
        height: calc(100% - 114px);
    }
}

.block-flyout-mobile__nav-list {
    margin-bottom: 100px;
}

.block-flyout-mobile__nav-list-link {
    position: relative;
    color: var(--c-petrol-1);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--h3);
    line-height: var(--l-height--h3);

    &:after {
        position: absolute;
        bottom: -6px;
        display: block;
        width: 32px;
        height: 2px;
        border: 50%;
        background-color: var(--c-white);
        content: '';
        opacity: 1;
    }
}

.block-flyout-mobile__nav-list-item {
    padding: 60px 0 60px 90px;

    &:hover {
        background: var(--c-petrol-2);
        .block-flyout-mobile__nav-list-link {
            color: var(--c-white);
        }
    }

    @include mobile {
        padding: 40px 30px
    }
}

.block-flyout-mobile__logo-home {
    margin: 60px 90px;

    @include mobile {
        margin: 30px;
    }
}

.block-flyout-mobile__gradient {
    position: absolute;
    z-index: 1;
    bottom: 114px;
    left: 0;
    width: 100%;
    height: 211px;
    background: linear-gradient(0deg, var(--c-white) 30.69%, rgba(110, 54, 52, 0) 100%);
    content: '';
    pointer-events: none;

    @include mobile {
        height: 114px;
    }
}

.block-flyout-mobile__bottom {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 211px;
    align-items: center;
    padding-left: 251px;
    background: var(--c-white);

    @include mobile {
        height: 114px;
        padding-left: 57px;
    }
}

.block-flyout-mobile__bottom-contact {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    row-gap: 30px;

    @include mobile {
        row-gap: 11px;
    }
}

.block-flyout-mobile__bottom-contact-deco {
    width: 100%;
    height: 5px;
    border-radius: 50px;
    background: var(--c-petrol-1);

    @include mobile {
        height: 2px;
    }
}

.block-header__navigation-login-mobile {
    display: none;

    @include tablet {
        display: flex;
        align-items: center;
        padding: 16px 20px;
        border: 2px solid v-bind(color4);
        border-radius: 4px;
        color: v-bind(color4);
        column-gap: 10px;
        font-family: var(--f-family--bold);
        font-size: var(--f-size--button);
        line-height: var(--l-height--button);
    }
}
</style>
