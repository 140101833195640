<template>
  <div class="block-form-login">
    <div class="block-form-login__content">
      <div class="block-form-login__form">
        <AtomHeading
          v-if="loginData?.title"
          :text="loginData.title"
          class="block-form-login__form-title"
          html-tag="h3"
          font-size="h3"
        />
        <AtomRichtext
          v-if="loginData?.text"
          :html="loginData.text"
          class="block-form-login__form-text"
        />
        <BlockFormController
          v-if="loginData?.fields"
          class="block-form-login__form-controller"
          :fieldsets="loginData.fields"
          :button-text="loginData.btnText"
          @on-submit="onSubmit"
        />
      </div>

      <transition name="fade-in">
        <div
          v-if="statusMessage"
          class="block-form-contant__message"
        >
          <AtomRichtext :html="`<p>${statusMessage}</p>`" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    btnText: {
        type: String,
        default: '',
    },

    fields: {
        type: Array,
        default: () => [],
    },

    successMessage: {
        type: String,
        default: '',
    },

    errorMessage: {
        type: String,
        default: '',
    },
});

const statusMessage = ref(null);
const loginData = getStoryblokSetting('login');

const modalboxStore = useModalboxStore();
const authStore = useAuthStore();

const onSubmit = async (data) => {
    try {
        await authStore.authenticateUser(data.username, data.password, loginData.value);

        const cookie = useCookie('token');
        const isValid = isValidUid(cookie.value);

        if (isValid) modalboxStore.closeModalbox();
        else throw new Error(loginData.value.errorMessage);
    } catch (e) {
        statusMessage.value = e;
    }
};
</script>

<style lang="scss" scoped>
.block-form-login {
    position: relative;
    max-width: 660px;
    background: var(--c-white);
    overflow-y: auto;

    @include mobile {
        flex: 1;
        padding: 20px;
        border-radius: 0;
        box-shadow: none;
    }
}

.block-form-login__content {
    @include grid-columns(2,16);
    @include fluid('margin-bottom', 30px, 50px);
}

.block-form-login__form {
    .block-form-controller__submit {
        align-self: flex-start;
    }
}

.block-form-login__form-controller {
    padding-bottom: 20px;
}

.block-form-login__form-title,
.block-form-login__form-text {
    padding-bottom: 30px;

    @include mobile {
        padding-bottom: 20px;
    }
}

.block-form-contant__message {
    margin-top: 40px;
}
</style>
