<template>
  <div class="block-footer">
    <UtilWrapperLayout>
      <UtilGrid class="block-footer__grid">
        <div class="block-footer__inner">
          <div class="block-footer__top">
            <AtomRichtext
              v-if="contact"
              class="block-footer__richtext"
              :html="richTextResolver.render(contact)"
            />
            <div class="block-footer__sitemap">
              <h3 class="block-footer__title">
                {{ getStoryblokTranslation('general', 'sitemap') }}
              </h3>
              <ul v-if="sitemap && sitemap.length > 0">
                <li v-for="(link, index) in sitemap" :key="index">
                  <AtomLink
                    v-if="link.link"
                    class="block-footer__sitemap-link"
                    :link="link.link"
                    :text="link.linkLabel"
                  />
                </li>
              </ul>
            </div>
            <div class="block-footer__newsletter">
              <h3 class="block-footer__title">
                {{ getStoryblokTranslation('general', 'newsletter') }}
              </h3>

              <AtomInputText
                label="E-Mail"
                name="e-mail"
                input-type="text"
                placeholder="E-Mail"
                appearance="secondary"
                @set-input="handleData"
              />
              <AtomButton
                class="block-footer__newsletter-btn"
                :text="getStoryblokTranslation('general', 'subscribe')"
                :is-disabled="modalStore.getEmail.length < 0 "
                @click="modalStore.getEmail && modalStore.toggle()"
              />
            </div>
          </div>
          <div class="block-footer__bottom">
            <div class="block-footer__copywrite">
              <span class="block-footer__copywrite-icon">©</span>
              <span>{{ getStoryblokTranslation('general', 'klik') }}</span>
            </div>

            <div class="block-footer__nav">
              <ul
                v-if="nav && nav.length > 0"
                class="block-footer__nav__list"
              >
                <li v-for="(link, index) in nav" :key="index">
                  <AtomLink
                    v-if="link.link?.id || link.link?.url"
                    class="block-footer__sitemap-link"
                    :link="link.link"
                    :text="link.linkLabel"
                  />
                </li>
              </ul>
            </div>
            <div class="block-footer__social-media">
              <a
                :href="linkedinUrl"
                target="_blank"
                rel="noopener noreferrer"
                class="block-footer__social-media-link"
              >
                <IonIcon
                  v-if="linkedinUrl"
                  class="block-footer__social-icon"
                  icon-name="linkedin"
                />
                <span class="block-footer__icon-label">{{ linkedinUrl }}</span>
              </a>
              <a
                :href="youtubeUrl"
                target="_blank"
                rel="noopener noreferrer"
                class="block-footer__social-media-link"
              >
                <IonIcon
                  v-if="youtubeUrl"
                  class="block-footer__social-icon"
                  icon-name="youtube"
                />
                <span class="block-footer__icon-label">{{ youtubeUrl }}</span>
              </a>

              <a
                v-if="facebookUrl"
                :href="facebookUrl"
                target="_blank"
                rel="noopener noreferrer"
                class="block-footer__social-media-link"
              >
                <span class="block-footer__icon-label">{{ facebookUrl }}</span>
                <IonIcon
                  class="block-footer__social-icon"
                  icon-name="facebook"
                />
              </a>
            </div>
          </div>
        </div>
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>
defineProps({
    contact: {
        type: Object,
        default: () => {},
    },

    sitemap: {
        type: Array,
        default: () => [],
    },

    facebookUrl: {
        type: String,
        default: '',
    },

    linkedinUrl: {
        type: String,
        default: '',
    },

    youtubeUrl: {
        type: String,
        default: '',
    },

    nav: {
        type: Array,
        default: () => [],
    },
});

const { richTextResolver } = useStoryblokApi();
const modalStore = useNewsletterModalStore();

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const color4 = computed(() => `var(--c-${colorTopLevel.value}-4)`);
const emit = defineEmits(['set-input', 'set-error']);

const handleData = (data) => {
    emit('set-input', data);
    if (data.value) {
        modalStore.setEmail(data.value);
    }
};

</script>

<style lang="scss">
.block-footer {
    padding-top: 73px;
    padding-bottom: 110px;
    background: var(--c-white);
    color: v-bind(color1);
    font-family: var(--f-family--thin);
    @include mobile {
        padding: 50px 0;
    }
}
.block-footer__inner {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    padding: 0;
    margin: 0 auto;
    gap: 60px;
    grid-column: 2 / span 16;

    @include mobile {
        gap: 30px;
        grid-column: 1 / span 3;
    }

    &:last-child {
        margin-bottom: 0;
    }

    @include tablet-portrait {
        flex-wrap: wrap;
        align-items: flex-start;
    }
}
.block-footer__top {
    display: flex;
    width: 100%;
    gap: 40px;

    @include mobile {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
}
.block-footer__bottom {
    display: flex;
    width: 100%;
    font-size: var(--f-size--richtext);
    gap: 40px;
    line-height: var(--l-height--richtext);
    @include mobile {
        flex-direction: column;
        gap: 0;

    }

}
.block-footer__richtext,
.block-footer__newsletter {
    flex-basis: 639px;
}
.block-footer__sitemap {
    flex-basis: 200px;
}
.block-footer__newsletter,
.block-footer__richtext,
.block-footer__sitemap {
    @include mobile {
        flex-basis: auto;
    }
}

.block-footer__title {
    @include fluid(font-size,  20px, 22px);
    @include fluid(line-height,  26px, 32px);
    margin-bottom: 18px;
    @include mobile {
        margin-bottom: 10px;
        font-family: var(--f-family--regular);
    }
}
.block-footer__copywrite-icon {
    padding-right: 40px;
    @include mobile {
        padding-right: 4px;
    }
}
.block-footer__nav__list {
    display: flex;
    color: v-bind(color4);
    gap: 40px;
}
.block-footer__icon-label {
    display: none;
}
.block-footer__social-media {
    display: flex;
    gap: 30px;
    @include mobile {
        gap: 20px;
    }
}
.block-footer__social-icon {
    width: 22px;
    height: 22px;
    path {
        stroke: v-bind(color4);
    }
    @include mobile {
        margin-top: 30px;
    }

}
.block-footer__newsletter-input {
    width: 100%;
    padding: 16px 20px;
    border: 1px solid v-bind(color4);
    border-radius: 0;
    margin-top: 5px;
    margin-bottom: 23px;
    -webkit-appearance: none;
    background-color: transparent;
    color: v-bind(color4);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    outline: none;
    @include mobile {
        padding: 16px 19px;
        margin-top: 0;
        margin-bottom: 10px;
    }

    &::placeholder {
        color: v-bind(color4);
    }
}
.block-footer__newsletter-input-label {
    display: none;
}

</style>
